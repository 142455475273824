export enum WeatherVariable {
  RAIN = 'rainfall_m_surface',
  TEMPERATURE = 'temperature_f_2m',
  CLOUD = 'cloudfraction_percent_all',
}
export type ForecastData = Record<WeatherVariable, Record<string, number>>;
export type ForecastResponse = {
  attrs: Record<string, unknown>;
  data: ForecastData;
};
