import { Coordinates } from '../types/Coordinates';
import {
  type PolicyBundle,
  PerilType,
  PolicyStatus,
  getPolicyStatus,
  type PayoutTier,
} from '../types/Entity';

/**
 * @description
 * Given a `policy bundle`, returns an array of `peril` coverage details.
 *
 * It first deconstructs the `perils` field from the first `exposure` on the first
 * `policy`. It then iterates over the array of `perils`, collecting only the fields
 * that pertain to the entire `policy bundle`.
 *
 * Only specific `peril` fields are returned for each `peril` because we want to
 * exclude fields specific only to the `policy`, and not the `policy bundle`. For example,
 * `peril.losses` will pertain only to the `policy`, not to the `policy bundle`.
 */
const perilCoverageForPolicyBundle = (
  policyBundle: PolicyBundle,
): { type: PerilType; parameterThresholdUpper: number }[] => {
  const [
    {
      exposures: [{ perils }],
    },
  ] = policyBundle.policies;

  // TODO: #1085: support parameterThresholdLower
  const perilCoverage = perils.map((peril) => {
    return {
      type: peril.type,
      parameterThresholdUpper: peril.parameterThresholdUpper,
    };
  });

  return perilCoverage;
};

/**
 * @description
 * Given a `policy bundle`, returns the timezone of the coverage.
 *
 * It deconstructs the `timezone` attribute from the `attributes` field from the first
 * `exposure` on the first `policy`.
 *
 * The timezone `attribute` lives only on the `policy` object, however a
 * `policy bundle` cannot have `policies` with coverage in different timezones. Therefore,
 * we can safely deconstruct the value from any of a `policy bundle`'s `policies`.
 */
const getTimeZoneFromPolicyBundle = (policyBundle: PolicyBundle): string => {
  const [
    {
      exposures: [
        {
          attributes: { timezone },
        },
      ],
    },
  ] = policyBundle.policies;

  return timezone;
};

/**
 * @description
 * Given a `policy bundle`, returns its payout tiers.
 *
 * It deconstructs the `payoutTiers` field from the first `exposure` on the first `policy`.
 *
 * The `payoutTiers` field lives only on the `policy` object, however a `policy bundle`
 * cannot have `policies` with different `payoutTiers`. Therefore, we can safely deconstruct
 * the value from any of a `policy bundle`'s `policies`.
 */
const getPayoutTiersPolicyBundle = (
  policyBundle: PolicyBundle,
): PayoutTier[] => {
  const [
    {
      exposures: [{ payoutTiers }],
    },
  ] = policyBundle.policies;

  return payoutTiers;
};

/**
 * @description
 * Given a `policy bundle`, returns the name of the location covered by the `exposure`.
 *
 * It deconstructs the `name` field from the first `exposure` on the first `policy`.
 *
 * The `exposure.name` field currently only represents the name of the location covered
 * by the exposure. This field lives only on the `exposure` object, however a `policy bundle`
 * cannot have `policies` with different `exposures`. Therefore, we can safely deconstruct
 * the value from any of a `policy bundle`'s `policies`.
 */
const getLocationNameFromPolicyBundle = (
  policyBundle: PolicyBundle,
): string => {
  const [
    {
      exposures: [{ name }],
    },
  ] = policyBundle.policies;

  return name;
};

/**
 * @description
 * Given a `policy bundle`, returns the coordinates covered by the `exposure`.
 *
 * It deconstructs the `attributes` field from the first `exposure` on the first `policy`.
 */
const getLocationCoordinatesFromPolicyBundle = (
  policyBundle: PolicyBundle,
): Coordinates => {
  const [
    {
      exposures: [{ attributes }],
    },
  ] = policyBundle.policies;

  return {
    lat: attributes.latitude,
    lng: attributes.longitude,
  };
};

/**
 * @description
 * Given a `policy bundle`, returns a boolean representing whether the entire bundle is upcoming,
 * or in other words, no single `policy` has begun.
 */
const policyBundleIsUpcoming = (policyBundle: PolicyBundle): boolean => {
  return policyBundle.policies.every((policy) => {
    return getPolicyStatus(policy) === PolicyStatus.Upcoming;
  });
};

export default {
  perilCoverageForPolicyBundle,
  getTimeZoneFromPolicyBundle,
  getPayoutTiersPolicyBundle,
  getLocationCoordinatesFromPolicyBundle,
  getLocationNameFromPolicyBundle,
  policyBundleIsUpcoming,
};
