import i18next from 'i18next';
import { useSelect } from 'downshift';
import { useAuth0 } from '@auth0/auth0-react';
import { useActions, useStore } from '../../store/hooks';
import { UserAPI } from '../../api';
import NeonGrassOverlayIcon from '../../sharedComponents/NeonGrassOverlayIcon/NeonGrassOverlayIcon';
import Typography from '../../sharedComponents/Typography/Typography';
import useLanguageConfig, {
  SupportedLanguages,
} from '../../hooks/useUserLanguageConfig';
import styles from './user-language-select.module.scss';

export interface UserLanguageSelectorProps {
  icon: JSX.Element;
  options: SupportedLanguages[];
}

const UserLanguageSelector: React.FC<UserLanguageSelectorProps> = ({
  icon,
  options,
}) => {
  const dispatch = useActions();
  const {
    user: { user },
  } = useStore();
  const { languageAbbreviationToWord, languageWordToAbbreviation } =
    useLanguageConfig();

  const { getAccessTokenSilently } = useAuth0();

  const languageChangeHandler = async (abv: string): Promise<void> => {
    if (user.id) {
      const token = await getAccessTokenSilently();
      const reqBody = { langLocale: abv };
      try {
        const updatedUser = await UserAPI.updateUser(token, reqBody);
        dispatch.setUser(updatedUser);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const changeLanguage = (lng: SupportedLanguages): void => {
    const abv = languageWordToAbbreviation(lng);
    void i18next.changeLanguage(abv);
    if (lng !== 'Test Keys') {
      void languageChangeHandler(abv);
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    selectedItem: languageAbbreviationToWord(i18next.language),
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (!newSelectedItem) return;
      changeLanguage(newSelectedItem);
    },
  });

  const renderOptions = options.map((item, index) => (
    <li
      className={
        highlightedIndex === index
          ? `${styles.langListItem} ${styles.selected}`
          : styles.langListItem
      }
      key={`${item}${index}`}
      {...getItemProps({ item, index })}
    >
      <Typography variant="label-3" align="left">
        {item}
      </Typography>
    </li>
  ));
  const globeIcon = <div className={styles.iconWrapper}>{icon}</div>;
  return (
    <div className={styles.container}>
      <div>
        {/*eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={styles.labelWrapper} {...getLabelProps()}></label>
        <div className={styles.iconWrapper} {...getToggleButtonProps()}>
          {isOpen ? <NeonGrassOverlayIcon svg={icon} /> : globeIcon}
        </div>
        <ul className={styles.langList} {...getMenuProps()}>
          {isOpen && renderOptions}
        </ul>
      </div>
    </div>
  );
};

export { UserLanguageSelector };
