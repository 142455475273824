import { useTranslation } from 'react-i18next';
import {
  PerilConfigurations,
  ParameterUnitDisplayMap,
  LossUnitDisplayMap,
} from '../../utils/types/Entity';
import Typography from '../../sharedComponents/Typography/Typography';
import { PerilCoverageDetailsProps } from './PerilCoverageDetails.types';

const PerilCoverageDetails: React.FC<PerilCoverageDetailsProps> = ({
  perilCoverage,
}) => {
  const { t } = useTranslation();

  const generateContent = (): string => {
    let content = '';
    // TODO: #2259 remodel the translationMap - description in the issue
    perilCoverage.forEach((c, i) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const perilConfig = PerilConfigurations[c.type];
      const displayUnit = ParameterUnitDisplayMap[perilConfig.unit];
      const displayLossUnit = LossUnitDisplayMap[perilConfig.lossUnit];
      //translationMap will handle all possible peril thresholds and return fully translated strings without interpolation
      const translationMap: Record<string, string> = {
        rainGreaterThan: t('label.rainfall_greater_than', {
          threshold: c.parameterThresholdUpper,
          unit: displayUnit,
          lossUnit: t(`label.abbr.${displayLossUnit}`),
        }),
        windGreaterThan: t('label.wind_greater_than', {
          threshold: c.parameterThresholdUpper,
          unit: displayUnit,
          lossUnit: t(`label.abbr.${displayLossUnit}`),
        }),
        precipitationGreaterThan: t('label.precipitation_greater_than', {
          threshold: c.parameterThresholdUpper,
          unit: displayUnit,
          lossUnit: t(`label.abbr.${displayLossUnit}`),
        }),
        temperatureGreaterThan: t('label.temperature_greater_than', {
          threshold: c.parameterThresholdUpper,
          unit: displayUnit,
          lossUnit: t(`label.abbr.${displayLossUnit}`),
        }),
      };
      //TODO: #1380 'greater than' - make dynamic. The value below will come from Protect
      // and doesn't need to be translated. It serves as an internal key for mapping the translations
      //it can be added as an attribute to the PerilConfiguration and returned as a display for keying
      const equivalenceRelation = 'GreaterThan';
      const text =
        translationMap[`${perilConfig.displayType}${equivalenceRelation}`];
      if (i === 0) {
        content = text;
      } else if (i === perilCoverage.length - 1) {
        content = `${content} ${t('label.or')} ${text}`;
      } else {
        content = `${content}, ${text}`;
      }
    });

    return content;
  };

  return <Typography variant="body-1">{generateContent()}</Typography>;
};

export default PerilCoverageDetails;
