import {
  StatusCode,
  statusTextFromCode,
} from '../types/StatusCode/StatusCode.types';

export class ValidationError extends Error {
  errors: string;
  value: unknown;
  location: string | undefined;
  isValidationError: boolean | undefined;

  constructor(
    value: unknown,
    valueName: string,
    errors: string[],
    location?: string,
  ) {
    const message = `${valueName} failed validation`;
    super(message);

    this.name = 'Validation Error';

    this.value = value;
    this.errors = errors.join('\n');
    this.location = location;
  }

  statusCode = (): StatusCode => {
    return StatusCode.UnprocessableEntity;
  };

  statusText = (): string => {
    return statusTextFromCode(this.statusCode());
  };
}

export function isValidationError(value: unknown): value is ValidationError {
  if (!(value instanceof Error)) return false;
  if (value.name !== 'Validation Error') return false;

  return true;
}
