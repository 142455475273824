// import { useTranslation } from 'react-i18next';
// import Typography from '../../sharedComponents/Typography/Typography';
// import styles from './forecast.module.scss';
import { Group } from '@visx/group';
import { curveMonotoneX } from '@visx/curve';
import { LinePath, Line, Bar, AreaClosed } from '@visx/shape';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom, AxisRight } from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { useParentSize } from '@visx/responsive';
import { localPoint } from '@visx/event';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { bisector } from '@visx/vendor/d3-array';
import { LinearGradient } from '@visx/gradient';
import { ResizeObserver } from '@juggle/resize-observer';
import RainGlyph from './RainGlyph';
import { ForecastData } from '../../utils/types/Entity';
import CloudGlyph from './CloudGlyph';
import SunGlyph from './SunGlyph';
import SensibleGlyph from './SensibleGlyph';

const background = '#f3f3f3';
const temperatureColor = '#6c0036';
const rainColor1 = '#03ffe0';
const rainColor2 = '#557b90';

const defaultMargin = { top: 80, right: 30, bottom: 70, left: 40 };

type ForecastProps = {
  maxHeight?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data?: ForecastData;
  rainfallThreshold?: number;
};

type ThresholdDataPoint = [Date, number];
type ThresholdData = ThresholdDataPoint[];

const Forecast = ({
  margin = defaultMargin,
  data,
  rainfallThreshold = 2,
  maxHeight,
}: ForecastProps): JSX.Element => {
  const { width, parentRef } = useParentSize({ debounceTime: 150 });
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    time: Date;
    temperature: number;
    rainfall: number;
    temperatureY: number;
    rainfallY: number;
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
    polyfill: ResizeObserver,
  });

  const height = maxHeight
    ? Math.min(width * 1.2, maxHeight - margin.top - margin.bottom)
    : width * 1.2;

  if (!data?.temperature_f_2m || !data?.rainfall_m_surface) {
    return (
      <div ref={parentRef}>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={background}
            rx={14}
          />
        </svg>
      </div>
    );
  }

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // scales
  const dates = Object.keys(data.temperature_f_2m).map((dateString) =>
    new Date(dateString).valueOf(),
  );
  const temperatures = Object.values(data.temperature_f_2m);
  const rainfalls = Object.values(data.rainfall_m_surface).map(
    (rainfall) => rainfall * 1000,
  );

  const timeScale = scaleTime<number>({
    domain: [Math.min(...dates), Math.max(...dates)],
    range: [0, xMax],
  });
  const temperatureScale = scaleLinear<number>({
    domain: [Math.min(...temperatures), Math.max(...temperatures)],
    nice: true,
    range: [yMax, 0],
  });
  const rainfallScale = scaleLinear<number>({
    domain: [
      Math.min(...rainfalls),
      Math.max(...rainfalls, rainfallThreshold * 2),
    ],
    nice: true,
    range: [yMax, 0],
  });

  const formatThresholdData = (
    forecastMetric?: Record<string, number>,
  ): ThresholdData => {
    if (!forecastMetric) {
      return [];
    }
    return Object.entries(forecastMetric).map(([dateString, value]) => [
      new Date(dateString),
      value,
    ]);
  };

  const thresholdTemperatureData: ThresholdData = formatThresholdData(
    data.temperature_f_2m,
  );
  const thresholdRainfallData: ThresholdData = formatThresholdData(
    data.rainfall_m_surface,
  ).map(([dateString, rainfall]) => [dateString, rainfall * 1000]);
  const thresholdCloudData: ThresholdData = formatThresholdData(
    data.cloudfraction_percent_all,
  );

  const bisectDate = bisector<ThresholdDataPoint, Date>(
    (data) => data[0],
  ).center;
  const handleMouseOver = (event: React.MouseEvent | React.TouchEvent) => {
    const coords = localPoint(event);
    if (!coords) return;

    const time = timeScale.invert(coords.x - margin.left);
    const index = bisectDate(thresholdTemperatureData, time);
    if (index >= thresholdTemperatureData.length) return;
    const temperatureDatum = thresholdTemperatureData[index];
    const rainfallDatum = thresholdRainfallData[index];

    showTooltip({
      tooltipLeft: coords.x - margin.left,
      tooltipTop: temperatureScale(temperatureDatum[1]),
      tooltipData: {
        time: temperatureDatum[0],
        temperature: temperatureDatum[1],
        rainfall: rainfallDatum[1],
        temperatureY: temperatureScale(temperatureDatum[1]),
        rainfallY: rainfallScale(rainfallDatum[1]),
      },
    });
  };

  const xTickDivisor = Math.ceil(33 / (xMax / temperatures.length));
  const xTickCount = Math.ceil(temperatures.length / xTickDivisor);

  const glyphDataPoints = !thresholdCloudData.length
    ? []
    : thresholdRainfallData
        .reduce<[Date, number, number][][]>((acc, cur, i) => {
          const dataPoint: [Date, number, number] = [
            ...cur,
            thresholdCloudData[i][1],
          ];
          if (i % xTickDivisor === 0) {
            acc.push([dataPoint]);
          } else {
            acc[acc.length - 1].push(dataPoint);
          }
          return acc;
        }, [])
        .map((dataPointGroup: [Date, number, number][]) => {
          const averageDate = new Date(
            dataPointGroup.reduce((acc, cur) => cur[0].getTime() + acc, 0) /
              dataPointGroup.length,
          );
          const maxRainfall = Math.max(
            ...dataPointGroup.map((dataPoint) => dataPoint[1]),
          );
          const maxCloud = Math.max(
            ...dataPointGroup.map((dataPoint) => dataPoint[2]),
          );
          return [averageDate, maxRainfall, maxCloud] as [Date, number, number];
        });

  return (
    <div ref={parentRef}>
      <svg width={width} height={height} ref={containerRef}>
        <LinearGradient
          id="rain-gradient"
          fromOffset="50%"
          from={rainColor2}
          to={rainColor1}
          toOpacity={0.8}
        />
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={background}
          rx={14}
        />
        <Group left={margin.left} top={margin.top}>
          <AxisRight
            scale={rainfallScale}
            label="rainfall (mm)"
            left={width - margin.right - margin.left}
          />
          <AxisBottom top={yMax} scale={timeScale} numTicks={xTickCount} />
          <AxisLeft scale={temperatureScale} />

          <GridRows
            scale={temperatureScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <GridColumns
            scale={timeScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0" />
          <AreaClosed<ThresholdDataPoint>
            data={thresholdRainfallData}
            curve={curveMonotoneX}
            x={(dataPoint) => timeScale(dataPoint[0]) ?? 0}
            y={(dataPoint) => rainfallScale(dataPoint[1]) ?? 0}
            yScale={rainfallScale}
            strokeWidth={1}
            stroke={'url(#rain-gradient)'}
            fill={'url(#rain-gradient)'}
          />
          <LinePath<ThresholdDataPoint>
            data={thresholdTemperatureData}
            curve={curveMonotoneX}
            x={(dataPoint) => timeScale(dataPoint[0]) ?? 0}
            y={(dataPoint) => temperatureScale(dataPoint[1]) ?? 0}
            stroke={temperatureColor}
            strokeWidth={1.5}
            strokeOpacity={0.8}
          />
          {glyphDataPoints.map(
            (
              [date, rainfall, cloud]: [Date, number, number],
              index: number,
            ) => {
              const x = timeScale(date) - 16;
              const y = -50;

              const Glyph =
                rainfall >= rainfallThreshold
                  ? RainGlyph
                  : cloud >= 50
                    ? CloudGlyph
                    : SunGlyph;

              return (
                <Glyph
                  left={x}
                  top={y}
                  key={`glyph-${xTickDivisor}-${index}`}
                />
              );
            },
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: height - margin.bottom - margin.top }}
                stroke="#222"
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={(tooltipTop || 0) + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={temperatureColor}
                stroke={temperatureColor}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={(tooltipData.rainfallY || 0) + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipData.rainfallY}
                r={4}
                fill={rainColor1}
                stroke={rainColor1}
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          <text
            x="-70"
            y={width - margin.right - margin.left - 15}
            transform="rotate(-90)"
            fontSize={10}
          >
            Rainfall (mm)
          </text>
          <text x="-70" y="15" transform="rotate(-90)" fontSize={10}>
            Temperature (°F)
          </text>
        </Group>
        <Bar
          x={margin.left}
          y={margin.top}
          width={width - margin.left - margin.right}
          height={height - margin.top - margin.bottom}
          fill="transparent"
          rx={14}
          onTouchStart={handleMouseOver}
          onTouchMove={handleMouseOver}
          onMouseMove={handleMouseOver}
          onMouseLeave={() => hideTooltip()}
        />
        <SensibleGlyph
          left={width - margin.right - 100}
          top={height - margin.bottom + 30}
        />
      </svg>
      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <br />
          <strong>{tooltipData?.time.toLocaleTimeString()}</strong>
          <br />
          <strong>Temperature:</strong> {tooltipData?.temperature.toFixed(1)}° F
          <br />
          <strong>Rainfall:</strong> {tooltipData?.rainfall.toFixed(2)} mm
        </TooltipInPortal>
      )}
    </div>
  );
};

export default Forecast;
