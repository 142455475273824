import { useTranslation } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import sensibleDate from '../../utils/types/SensibleDate';
import { type Claim, PayoutProvider } from '../../utils/types/Entity';
import { ClaimStatusWrapperComponent } from '../../components';
import { ProcessingClaimsProps } from './ProcessingClaims.types';
import styles from './processing-claims.module.scss';

const ProcessingClaims: React.FC<ProcessingClaimsProps> = ({
  policyBundle,
  processingClaims,
}): JSX.Element => {
  const { t } = useTranslation();

  if (processingClaims.length === 0) {
    return <></>;
  }
  const sortedClaims = processingClaims.sort((a: Claim, b: Claim) => {
    if (!a.payout || !b.payout) return 0;

    const aDate = sensibleDate.parseRFC(a.payout.createdAt);
    const bDate = sensibleDate.parseRFC(b.payout.createdAt);

    if (aDate > bDate) {
      return -1;
    }

    if (aDate < bDate) {
      return 1;
    }

    return 0;
  });

  const groupClaimsByDate = (): Record<string, Claim[]> => {
    return sortedClaims.reduce<Record<string, Claim[]>>((result, claim) => {
      const date = claim.payout?.completedAt?.split(':')[0] ?? '';
      if (result[date]) {
        result[date].push(claim);
      } else {
        result[date] = [claim];
      }
      return result;
    }, {});
  };

  const bundledClaims = groupClaimsByDate();

  const bundledClaimsValues = Object.values(bundledClaims);
  const [mostRecentlyPaidOut, ...previouslyPaidOut] = bundledClaimsValues;
  const renderMostRecentlyPaidOut = (): JSX.Element => {
    if (mostRecentlyPaidOut.every((c) => !c.payout)) return <></>;

    const totalPayoutAmount = mostRecentlyPaidOut.reduce(
      (acc, value) => acc + (value.payout?.amount ?? 0),
      0,
    );
    const formattedAmount = t('formatter.currency', {
      val: totalPayoutAmount,
      currency: mostRecentlyPaidOut[0]?.payout?.currency,
    });
    const payoutProviderText = {
      [PayoutProvider.ACH]: {
        providerProcessingTime: t('sunshine.content.bank_processing_time', {
          amount: formattedAmount,
        }),
      },
      [PayoutProvider.PayPal]: {
        providerProcessingTime: t('sunshine.content.paypal_processing_time', {
          amount: formattedAmount,
        }),
      },
    };

    let text = t('sunshine.content.generic_processing_time', {
      amount: formattedAmount,
    });

    if (mostRecentlyPaidOut[0]?.payout?.provider) {
      text =
        payoutProviderText[mostRecentlyPaidOut[0].payout.provider][
          'providerProcessingTime'
        ];
    }

    return <Typography variant="body-1">{text}</Typography>;
  };

  const renderPreviouslyPaidOut = (): JSX.Element => {
    const previouslyPaidOutElements: JSX.Element[] = [];
    previouslyPaidOut.forEach((claim, i) => {
      if (claim.every((c) => !c.payout)) return;
      const totalPayoutAmount = claim.reduce(
        (acc, value) => acc + (value.payout?.amount ?? 0),
        0,
      );
      const formattedAmount = t('formatter.currency', {
        val: totalPayoutAmount,
        currency: claim[0]?.payout?.currency,
      });

      const transferDate = t('formatter.date', {
        val: sensibleDate.parseRFC(claim[0]?.payout?.createdAt ?? ''),
        formatParams: {
          val: {
            month: 'long',
            day: 'numeric',
          },
        },
      });
      const payoutProviderText = {
        [PayoutProvider.ACH]: {
          payoutSentViaProvider: t('sunshine.content.bank_payout_sent_to_you', {
            amount: formattedAmount,
            date: transferDate,
          }),
        },
        [PayoutProvider.PayPal]: {
          payoutSentViaProvider: t(
            'sunshine.content.paypal_payout_sent_to_you',
            {
              amount: formattedAmount,
              date: transferDate,
            },
          ),
        },
      };

      let text = t('sunshine.content.generic_payout_sent_to_you', {
        amount: formattedAmount,
      });

      if (claim[0]?.payout?.provider) {
        text =
          payoutProviderText[claim[0].payout.provider]['payoutSentViaProvider'];
      }

      previouslyPaidOutElements.push(
        <Typography variant="body-2" key={`c-${i}`}>
          {text}
        </Typography>,
      );
    });

    return <>{previouslyPaidOutElements}</>;
  };

  return (
    <ClaimStatusWrapperComponent policyBundle={policyBundle}>
      <div className={styles.container}>
        {renderMostRecentlyPaidOut()}
        {renderPreviouslyPaidOut()}
      </div>
    </ClaimStatusWrapperComponent>
  );
};

export default ProcessingClaims;
