import { BackendModule, InitOptions, ReadCallback, Services } from 'i18next';
import { HygraphClient } from './HygraphClient';

interface BackendOptions {
  reloadInterval: false | number;
  hygraphUrl: string;
  hygraphKey?: string;
  hygraphStage: string;
}

type BackendOptionsOrPromise = BackendOptions | Promise<BackendOptions>;

// eslint-disable-next-line @typescript-eslint/naming-convention
class i18nextHygraphPlugin implements BackendModule<BackendOptionsOrPromise> {
  static type = 'backend' as const;
  type = 'backend' as const;
  private hygraphClient?: HygraphClient;
  private readyPromise?: Promise<BackendOptions>;
  public backendOptions?: BackendOptions;
  constructor(
    public services: Services,
    backendOptionsOrPromise: BackendOptionsOrPromise,
    public i18nextOptions: InitOptions = {},
  ) {
    void this.init(services, backendOptionsOrPromise, i18nextOptions);
  }
  async init(
    services: Services,
    backendOptionsOrPromise: BackendOptionsOrPromise,
    i18nextOptions: InitOptions,
  ): Promise<void> {
    this.services = services;
    this.i18nextOptions = i18nextOptions;
    this.readyPromise = Promise.resolve(backendOptionsOrPromise);
    this.backendOptions = await this.readyPromise;

    if (this.backendOptions?.reloadInterval) {
      setInterval(() => this.reload(), this.backendOptions.reloadInterval);
    }
  }

  private initHygraphClient(): void {
    if (!this.hygraphClient && this.backendOptions) {
      this.hygraphClient = new HygraphClient(
        this.backendOptions.hygraphUrl,
        this.backendOptions.hygraphStage,
        this.backendOptions.hygraphKey,
      );
    }
  }

  reload(): void {
    const languages = Array.isArray(this.i18nextOptions.preload)
      ? (this.i18nextOptions.preload as string[])
      : [this.i18nextOptions.lng as string];

    const namespaces = Array.isArray(this.i18nextOptions.ns)
      ? (this.i18nextOptions.ns as string[])
      : [this.i18nextOptions.ns as string];

    languages.forEach((language) => {
      namespaces.forEach((ns) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        this.services.backendConnector.read(
          language,
          ns,
          'read',
          null,
          null,
          (err: unknown, data: unknown) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            this.services.backendConnector.loaded(
              `${language}|${ns}`,
              err,
              data,
            );
          },
        );
      });
    });
  }

  async read(
    language: string,
    // @ts-expect-error:next-line
    namespace: string,
    callback: ReadCallback,
  ): Promise<void> {
    await this.readyPromise;
    this.initHygraphClient();
    void this.hygraphClient
      ?.fetchStringsForLocale(language)
      .then((results) => {
        callback(null, results);
      })
      .catch((err: Error) => {
        callback(err, {});
      });
  }
}

export { i18nextHygraphPlugin };
